.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.app-content {
  padding-top: 1%;
  text-align: center;
  margin-top: 60px;
}
.App-body {
  max-width: 420px;
  margin: 0 auto;
  margin: 0 auto;
  margin-top: 60px;
  font-size:xx-large ;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.app-container{
  height: 100vh;
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: #f0f2f5;
}
.container{
  flex-grow: 1;
  background: #f0f2f5;
}
.page-title{
  font-size:  xx-large;
  margin: 20px 0;
}
/* .button{
  background-color: #222;
} */
/* .ant-btn-primary {
  background-color: red;
} */
.table{
  width: 1200px;
  margin: auto;
} 