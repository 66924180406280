.button-table {
    align-content: center;
    margin: auto;
    width: 500px;
}
.red{
    color: red; 
  }
.green {
     color :green; 
}
.amount-table{
    color: blueviolet ;
    width: 1000px;
    margin: auto;
} 
.auto {
    color :black; 
} 
.numeric-input{
 text-align: right;   
}