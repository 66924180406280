.forgotpassword-container {
    max-width: 420px;
    margin: 0 auto;
    margin-top: 60px;    
}

.forgotpassword-form-button {
    width: 100%;
    margin-top: 15px;
}

.forgotpassword-form-button[disabled], .forgotpassword-form-button[disabled]:hover, .forgotpassword-form-button[disabled]:focus {
    opacity: 0.6;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
}

.forgotpassword-form input {
    margin-bottom: 3px;
}
h1 {
 color: black;   
}