body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
html {
  padding:0;
  margin: 0;
  min-width: 1000px;
  overflow-x: auto;
}