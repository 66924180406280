.app-footer {

    padding: 0;

    flex-shrink: 0;
  }
  .logos_bar{
    background: #f0f2f5;
  }
  .app-rights{
    display: flex;
    justify-content: center; /*Центрирование по горизонтали*/
    align-items: center;     /*Центрирование по вертикали */
    background-color: #234965;
    color: white;
    height:  42px;
  }
  .signup-form-button {
    width: 100%;
    margin-top: 15px;
}

.signup-form-button[disabled], .signup-form-button[disabled]:hover, .signup-form-button[disabled]:focus {
    opacity: 0.6;
    color: #fff;
    /* background-color: #1890ff; */
    border-color: #1890ff;
}