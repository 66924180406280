.ant-input-number {
    width: 120px;
}
.select-flat {
    margin: 10%;    
}
.home-content{
    margin: 2% 2%;
}
.home-table{
    margin-top: 10px;
}
